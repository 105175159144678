body {
  padding: 20px;
  font-family: Helvetica;
}

.form-control:focus {
  border-color: #20262e !important;
}

.theme-smart-material {
  background: #0d47a1;
}

.theme-clean-white {
  background: #ffff;
}

.theme-dark-blue {
  background: #20262e;
}

.theme-selection-button {
  height: 25px;
  width: 25px;
  border: #000 1px solid;
  border-radius: 50%;
  display: inline-block;
}

.theme-selection-button:hover {
  border: green 2px solid;
  border-radius: 33%;
}

#banner-message {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  font-size: 25px;
  text-align: center;
  transition: all 0.2s;
  margin: 0 auto;
  width: 621px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

button {
  background: #0084ff;
  border: none;
  border-radius: 5px;
  padding: 8px 14px;
  font-size: 15px;
  color: #fff;
}

#banner-message.alt {
  background: #0084ff;
  color: #fff;
  margin-top: 40px;
  width: 200px;
}

#banner-message.alt button {
  background: #fff;
  color: #000;
}

p.textSize12 {
  font-size: 12px;
}

p.textSize14 {
  font-size: 14px;
}

.textSize16 {
  font-size: 16px;
}

.modal {
  max-width: 623px !important;
}

#name {
  width: 189px;
  /* display: inline-block; */
}

.logo-branch {
  width: 70px;
}

.title {
  margin-top: 10px;
}

.btn-setting {
  color: #fff;
}

.btn-setting:hover,
.btn-setting:focus {
  color: #fff;
}

.display-block {
  display: block;
}

.hide {
  display: none;
}

.blocker {
  background-color: rgba(26, 75, 249, 0.1) !important;
}

.flash-messages {
  left: auto !important;
  top: auto !important;
  right: 10px !important;
  bottom: 50px;
}

.text-center {
  text-align: center;
  text-align: -webkit-center;
}